<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card class="pt-3">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid ri-parent-fill" aria-hidden="true" style="font-size: 20px; margin-right: 10px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
            <br><h5>{{analytics.event_name}}</h5>
            <span style="word-break: break-all;">Id: {{ eventId }}</span><br>
            <span v-if="analytics.event_meeting_id">Meeting Id: {{analytics.event_meeting_id}}<br></span>
            <span v-if="analytics.org_name">
              {{analytics.org_name}}{{analytics.org_city ? ', ' + analytics.org_city : '' }}{{analytics.org_state ? ', ' + analytics.org_state : '' }}{{analytics.org_country ? ', ' + analytics.org_country : '' }}<br/>
            </span>
            <span>Starts On: {{analytics.event_start_ts | dateFormatDDMMYYYYHHMMA}}</span><br>
            <span>Duration: {{(analytics.event_end_ts - analytics.event_start_ts) | durationToDHM}}</span><br>
          </template>
          <template v-slot:body>
            <div class="row w-100 mb-3 multiple_boxes_row " :key="updateKeys">
              <div class="ml-2" v-for="(value, key, index) in eventAnalyticsCountObj" :key="key+value">
                <div class="boxs" :style="'background:'+getBgFor(index)">
                  <iq-card class="btn mb-1 multiCardboxs" >
                    <span  class="textintworow text-white">{{key}} </span><br/>
                    <span class="badge badge-light">{{analytics[value]}}</span>
                  </iq-card>
                </div>
              </div>
            </div>
            <b-row class="mb-3">
              <b-col class="col-12 col-sm-12 col-md-4 col-lg-4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"
                ></b-form-input>
              </b-col>
              <div class="col-12 col-sm-12 col-md-8 col-lg-8">
                <div class="row m-0 p-0">
                  <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 mt-2">
                    <b-button variant="primary" class="download_csv_button mr-1 mb-1 pull-right px-0" v-if="userPermission('upload_participants')">
                      <CsvDownload :propModuleFile="cvModuleFile" class="btn mr-1 Download_button" title="Download Template"/>
                    </b-button>
                    <b-button variant="primary" class="download_csv_button mr-1 mb-1 pull-right px-0" v-if="userPermission('upload_participants')">
                      <CsvUpload :propModulePrefix="cvModulePrefix"/>
                    </b-button>
                    <b-button variant="primary" class="mr-1 pull-right" v-if="userPermission('add_participant')" @click="goToContactUsForm">
                      Add Student
                    </b-button>
                  </b-col>
                  <b-col class="col-12 col-sm-6 col-md-6 col-lg-6 mt-2" v-if="meetingReportObjList && meetingReportObjList.length && userPermission('download_participant_report')">
                    <VueJsonToCsv :json-data="meetingReportObjList" class="pull-right" csv-title="event_rsvp" >
                      <b-btn variant="primary mb-1">Download</b-btn>
                    </VueJsonToCsv>
                    <b-button variant="primary" class="mr-1 mb-1 pull-right" v-if="userPermission('sync_data')" @click="meetingReportSync">
                      Sync Data
                    </b-button>
                  </b-col>
                </div>
              </div>
            </b-row>
            <b-row>
              <b-col md="12" v-if="meetingReportObjList && meetingReportObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="meetingReportObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :key="updateKeys"
                >
                  <template v-slot:cell(sno)="data">
                    {{data.index + 1}}
                  </template>

                  <template v-slot:cell(user_name)="data">
                    {{data.item.user_name}} <br>
                    <small>
                      (Id)&nbsp;{{data.item.user_id}}<br>
                      (E)&nbsp;{{data.item.user_email}}<br>
                      (M)&nbsp;{{data.item.user_mobile}}<br>
                    </small>
                    <i class="fa-solid fa-copy pointer font-size-20" title="Copy User Id" @click="doCopy(data.item.user_id)"></i>&nbsp;&nbsp;
                    <router-link :to="'/user/'+ data.item.user_id" style="color:black !important">
                      <i title="View User Profile" class="fa-solid fa-eye font-size-20 mt-1"></i>
                    </router-link>
                    <i title="Mark attendance" class=" fa fa-check font-size-20 ml-3 mt-1" v-if="userData.user_role === 'USERROLE11111' && analytics.event_hosting_platform && !analytics.event_hosting_platform.includes('ZOOM')" style="cursor: pointer;" aria-hidden="true" @click="eventUserCheckin(data.item) "></i>
                  </template>

                  <template v-slot:cell(org_name)="data">
                    <span v-if="data.item.org_name">
                      {{`${data.item.org_name ? data.item.org_name + ", " : " - "} ${data.item.org_name && data.item.org_city ? data.item.org_city + ", " : ""} ${data.item.org_name && data.item.org_state ? data.item.org_state + ", " : ""} ${data.item.org_name && data.item.org_country ? data.item.org_country + ", " : ""}`}}
                    </span>
                    <span v-else>
                      {{data.item.cls_school_name}}
                    </span>
                  </template>

                  <template v-slot:cell(parent_email)="data">
                    <span v-if="data.item.parent_email">
                    <small><br><br>
                      (E)&nbsp;{{ data.item.parent_email }}<br>
                      (M)&nbsp;{{ data.item.parent_mobile }}
                    </small>
                    </span>
                  </template>

                  <template v-slot:cell(user_join_time)="data">
                    {{data.item.user_join_time | dateFormatDDMMYYYYHHMMA}}
                  </template>

                  <template v-slot:cell(duration)="data">
                    {{data.item.duration | durationToDHM}}
                  </template>

                  <template v-slot:cell(class_name)="data">
                    {{data.item.class_name ? data.item.class_name : data.item.grade | addSuffixToClass}}
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.multiCardboxs{
  background-color: transparent !important;
  width: 99% !important;
  display: block;
  height: 85px;
}
</style>

<script>
import { events } from "../../../FackApi/api/events.js"
import { socialvue } from "../../../config/pluginInit.js"
import { evantAnlytics } from "../../../FackApi/api/analytics/eventAnalytic.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import ColorCodes from "../../../Utils/colorcodes.js"
import CsvUpload from "../../../components/Csv/CsvUpload.vue"
import CsvDownload from "../../../components/Csv/CsvDownload.vue"
import eventHostingPlatformConfig from "../../../FackApi/json/EventHostingPlatforms.js"
import moment from "moment"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "EventParticipantReport",
  components: {
    CsvUpload,
    CsvDownload
  },
  data () {
    return {
      apiName: "eventRsvp_list",
      cvCardTitle: "Event Participants Report",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event Participants Report",
      sortBy: "name",
      sortDesc: false,
      columns: [],
      meetingReportObjList: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      eventId: null,
      analytics: {},
      orgId: null,
      updateKeys: 0,
      currentTS: moment().utc().valueOf() / 1000,
      eventAnalyticsCountObj: { // Key value pair where key is label and value will use to get data from api
        "Registered": "event_total_regs",
        "Joined": "event_total_joins",
        "Join %": "event_join_per",
        "Attended": "event_total_attendees",
        "Attend%": "event_attendance_per"
      },
      cvModuleFile: "event_rsvp_attendance",
      cvModulePrefix: "event_rsvp",
      eventHostingPlatformConfig: eventHostingPlatformConfig
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    if (this.userData.user_role == "USERROLE11117") {
      // Allowed
      return
    }

    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.orgId = this.$route.params.orgId
    this.eventHostingPlatformConfig = eventHostingPlatformConfig(this.userData.user_role)

    switch (this.userData.user_role) {
      case "USERROLE11111": // Admin
      case "USERROLE11118":
        this.columns = [
          { label: "# ", key: "sno", class: "text-left", sortable: true },
          { label: "Source", key: "source", class: "text-left w-320px", sortable: true },
          { label: "Name", key: "user_name", class: "text-left w-320px", sortable: true },
          { label: "User Type", key: "usr_type", class: "text-left", sortable: true },
          { label: "Parent Contact", key: "parent_email", class: "text-left", sortable: true },
          { label: "Passing Year", key: "passing_year", class: "text-left", sortable: true },
          { label: "Prefered country", key: "preferred_country", class: "text-left", sortable: true },
          { label: "Board", key: "board", class: "text-left", sortable: true },
          { label: "School", key: "org_name", class: "text-left", sortable: true },
          { label: "Class/Grade", key: "class_name", class: "text-left", sortable: true },
          { label: "Joined", key: "event_joined", class: "text-left", sortable: true },
          { label: "Attended", key: "event_attendance", class: "text-left", sortable: true },
          { label: "Duration", key: "duration", class: "text-left", sortable: true }
        ]
        break
      case "USERROLE11117": // School
        this.columns = [
          { label: "# ", key: "sno", class: "text-left", sortable: true },
          { label: "Name", key: "user_name", class: "text-left w-320px", sortable: true },
          { label: "Class/Grade", key: "class_name", class: "text-left", sortable: true },
          { label: "Joined", key: "event_joined", class: "text-left", sortable: true },
          { label: "Attended", key: "event_attendance", class: "text-left", sortable: true },
          { label: "Duration", key: "duration", class: "text-left", sortable: true }
        ]
        break
      case "USERROLE11115": // Univ Admin
      case "USERROLE11116": // Organisation
        this.columns = [
          { label: "# ", key: "sno", class: "text-left", sortable: true },
          { label: "Source", key: "source", class: "text-left w-320px", sortable: true },
          { label: "Name", key: "user_name", class: "text-left w-320px", sortable: true },
          { label: "User Type", key: "usr_type", class: "text-left", sortable: true },
          { label: "Parent Contact", key: "parent_email", class: "text-left", sortable: true },
          { label: "Passing Year", key: "passing_year", class: "text-left", sortable: true },
          { label: "Prefered country", key: "preferred_country", class: "text-left", sortable: true },
          { label: "Board", key: "board", class: "text-left", sortable: true },
          { label: "School", key: "org_name", class: "text-left", sortable: true },
          { label: "Class/Grade", key: "class_name", class: "text-left", sortable: true },
          { label: "Joined", key: "event_joined", class: "text-left", sortable: true },
          { label: "Attended", key: "event_attendance", class: "text-left", sortable: true },
          { label: "Duration", key: "duration", class: "text-left", sortable: true }
        ]
        break
      default:
        break
    }

    this.eventId = this.$route.params.eventId
    this.eventMeetingId = this.$route.query.meeting_id
    socialvue.index()

    this.eventRsvpList()
    this.eventAnalyticsCounts()
  },
  methods: {

    /**
     * userPermission
     */
    userPermission (actionName) {
      return userPermission(this, this.userData, actionName)
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * getBgFor
     */
    getBgFor (index) {
      return ColorCodes.getBgColor(index)
    },
    /**
     * meetingReportSync
     */
    async meetingReportSync () {
      if ((!this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
        this.toastMsg = "Warning: UnAuthorized Access"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      try {
        let eventObj = {
          org_id: this.orgId
        }
        eventObj.sync_report = true
        eventObj.event_meeting_id = this.eventMeetingId

        let meetingReportSyncResp = await events.eventRsvpSync(this, this.eventId, eventObj)
        ApiResponse.responseMessageDisplay(this, meetingReportSyncResp)
      }
      catch (err) {
        console.log("Exception occurred at meetingReportSync() and Exception:", err.message)
      }
    },
    /**
     * eventRsvpList
     */
    async eventRsvpList () {
      try {
        let eventObj = {
          org_id: this.orgId
        }

        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "ORG"
        }

        let eventRsvpListResp = await events.eventRsvpList(this, this.eventId, eventObj)
        if (eventRsvpListResp.resp_status) {
          this.meetingReportObjList = eventRsvpListResp.resp_data.data
          this.totalRows = eventRsvpListResp.resp_data.count
        }
        else {
          this.toastMsg = eventRsvpListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at eventRsvpList() and Exception:", err.message)
      }
    },
    /**
     * eventAnalyticsCounts
     */
    async eventAnalyticsCounts () {
      try {
        let eventObj = {
          org_id: this.orgId,
          event_id: this.eventId
        }

        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0) {
          eventObj.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          eventObj.module_name = "ORG"
        }

        let eventAnalyticResp = await evantAnlytics.eventAnalyticsCounts(this, eventObj)
        if (eventAnalyticResp.resp_status) {
          this.analytics = eventAnalyticResp.resp_data.data
          this.analytics.event_join_per = (this.analytics.event_total_regs !== 0 ? (this.analytics.event_total_joins / this.analytics.event_total_regs * 100).toFixed(2) : "0") + "%"
          this.analytics.event_attendance_per = (this.analytics.event_total_joins !== 0 ? (this.analytics.event_total_attendees / this.analytics.event_total_joins * 100).toFixed(2) : "0") + "%"
        }
        else {
          this.toastMsg = eventAnalyticResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at eventAnalyticsCounts() and Exception:", err.message)
      }
    },
    goToContactUsForm () {
      let url = "/contactUsV2?source=" + this.eventId + "&module_id=" + this.eventId + "&module_name=" + this.analytics.event_name + "&module_type=EVENT"
      window.open(url, "_blank")
    },
    /**
     * eventUserCheckin
    */
    async eventUserCheckin (item) {
      let duration = this.analytics.event_end_ts - this.analytics.event_start_ts
      this.updateKeys++

      let attendeesObj = {
        user_email: item.user_email,
        event_id: this.eventId,
        duration: duration
      }

      let eventUserAttendance = await events.eventUserCheckin(this, attendeesObj)
      if (eventUserAttendance.resp_status) {
        ApiResponse.responseMessageDisplay(this, eventUserAttendance)
        this.eventAnalyticsCounts()
        item.event_attendance = 1
        item.event_joined = 1
        item.duration = duration
      }
      else {
        this.toastMsg = eventUserAttendance.resp_msg
        this.toastVariant = "danger"
        this.showToast = true
      }
    }
  }
}
</script>
